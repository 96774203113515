.notification-card {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.swipe-indicator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding: 10px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}


.notification-card.swiping-left .left-indicator {
  opacity: 0.8;
}

.notification-card.swiping-right .right-indicator {
  opacity: 0.8;
}

.notification-card.swipe-left {
  transform: translateX(-100%);
}

.notification-card.swipe-right {
  transform: translateX(100%);
}

.swipe-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s;
}

.notification-card.swipe-left .swipe-icon,
.notification-card.swipe-right .swipe-icon {
  opacity: 1;
}

.left-indicator {
  left: 10px;
  background-color: green;
}

.right-indicator {
  right: 10px;
  background-color: red;
}