@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #F27131; /* Default value */
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.bg-primary {
  background-color: var(--primary-color);
}

.text-primary {
  color: var(--primary-color);
}

.border-primary {
  border-color: var(--primary-color);
}

/* styles.css or tailwind.config.js */
.bg-red-100 {
  background-color: #fee2e2;
}

.bg-green-100 {
  background-color: #d1fae5;
}

.bg-blue-100 {
  background-color: #dbeafe;
}

.bg-gray-100 {
  background-color: #f3f4f6;
}