/* top-bar.css */
.top-bar {
  position: relative;
  background-color: #313441;
  /* Or any color you prefer */
  font-size: 0.875rem;
  color: white;
  height: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  z-index: 2;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.top-bar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-bar a {
  margin-right: 1rem;
  color: #333;
  /* Adjust the color as needed */
}

.top-bar .social-icons a {
  margin-left: 1rem;
}

.top-bar button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Home.css */
.top-bar a:hover,
.top-bar a:focus {
  color: #FFB759 !important;
  /* Change la couleur des liens au survol ou au focus */
}

/* Si vos icônes sont dans un span ou un élément similaire */
.top-bar a:hover .icon,
.top-bar a:focus .icon {
  color: #FFB759 !important;
  /* Change la couleur de l'icône au survol ou au focus */
}

.logo-section {
  position: absolute;
  top: 0;
  z-index: 10;
  /* S'assurer qu'il se trouve au-dessus des autres éléments */
  width: 270px;
  /* Largeur fixe de 250px */
  height: 100%;
  /* Hauteur totale de la navbar + topbar */
  background-color: #ffc86d;
  /* Couleur de fond */
}

.logo-section-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10px;
  /* Ajuste selon le besoin */
  position: relative;
  /* Nécessaire pour positionner les éléments enfants */
}

.logo-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: -50px;
  /* Extension sur la gauche pour couvrir l'angle après la transformation */
  width: calc(100% + 50px);
  /* Largeur totale plus l'extension sur la gauche */
  height: 100%;
  background-color: #ffc86d;
  transform: skewX(45deg);
  transform-origin: top left;
  z-index: -2;
  /* Origine de la transformation */
}

.logo-section img {
  height: 100%;
  /* Le logo prend toute la hauteur de la .logo-section */
  width: auto;
  /* Garder les proportions du logo */
  margin-right: 20px;
}

.logo-section::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #ffc86d;
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
  z-index: -1;
}

.logo-section img {
  height: 100%;
  /* Le logo prend toute la hauteur */
}

.navbar {
  position: relative;
  display: flex;
  align-items: center;
  /* Aligner verticalement les enfants */
  justify-content: space-between;
  /* Espacer équitablement les enfants */
  background-color: #FAFAFA !important;
    /* Assure un fond clair */
  /* ou toute autre couleur de fond que tu préfères */
  z-index: 2;
  border-bottom: 1px solid rgb(114, 114, 114);
}

.navbar-item-navlinks,
.navbar-brand a,
.name__role {
  color: black !important;
  flex-grow: 1;
  /* Texte en noir */
}

.navbar-item-navlinks {
  position: relative;
  /* Nécessaire pour positionner correctement le ::before */
  transition: color 0.3s;
  /* Transition douce pour le changement de couleur */
  /* Majuscule */
  text-transform: uppercase;
}

/* Style pour l'effet hover sur les liens de la navbar */
.navbar-item-navlinks:hover::before {
  content: '';
  position: absolute;
  bottom: 20px;
  /* Ajuste selon le besoin pour aligner l'effet sous le lien */
  left: 0;
  right: 0;
  height: 4px;
  background-color: #FFB759 !important;
  /* Barre jaune au survol */
  width: 100%;
}

.nav-dropdown-item:hover{
  background-color: #FFB859 !important;
  /* Couleur au survol */
  color:#000 !important;
}

.dropdown-item{
  color: #FFF !important;
}

.dropdown-item:hover {
  background-color: #FFB859 !important;
  color: #000 !important;
  /* Couleur au survol */
  height: 100%;
}

.navbar-end {
  margin-left: 25px;
  /* Ajoute une marge à gauche du conteneur .navbar-end */
  display: flex;
  align-items: center;
}

/* Ou, si vous préférez ajouter la marge au dernier élément de .navbar-start */
.navbar-start .navbar-item-navlinks:last-child {
  margin-right: 25px;
  /* Ajoute une marge à droite du dernier élément de menu */
}

.navbar-start{
  flex: 1;
  /* Prend tout l'espace disponible */
  justify-content: flex-end !important;
}

/* Cacher le menu burger sur les écrans larges et l'afficher sur les écrans plus petits */
.navbar-burger {
  display: none;

}

.burger-icone{
  justify-content: flex-end;
  color: #313441;
  /* Couleur du burger */
  font-size: 1.25rem;
  /* Taille de l'icône */
  /* position centrée */
  align-self: center;
  /* Ajuste selon le besoin */
  align-items: center;
  position: absolute;
  right: 15px;
  top: 15px;
  /* Ajuste selon le besoin */
  z-index: 100;
  /* Assure que le bouton burger est au-dessus des autres éléments */
}

@media screen and (max-width: 1024px) {

  /* Ajustez la largeur selon vos besoins */
  .navbar-burger {
    display: flex;
    cursor: pointer;
    z-index: 100;
    /* agrandit l'icone pour faciliter le clic */
    border-radius: 50%;
    align-self: center;
    /* Centre le bouton verticalement si nécessaire */
    background-color: #FFB759 !important;
  
    /* Ajuste selon le besoin */
    z-index: 100;
    /* Assure que le bouton burger est au-dessus des autres éléments */
    border-radius: 50%;
    /* Rend le bouton burger rond */
    padding: 10px;
    /* Ajuste selon le besoin */
  }

  .navbar-menu {
    display: none;
    /* Cache le menu navbar-menu par défaut sur les petits écrans */
  }

  .navbar-menu.is-active {
    display: block;
    /* Affiche le menu lorsque isActive est vrai */
  }

  .logo-section {
    display: none;
    /* Cache le logo sur les petits écrans */
  } 

    .navbar-item-navlinks:hover::before {
    display: none;
    }
}

.text-spaced {
  line-height:  2;
}

.card-cours{
  min-width: 425px;
  max-width: 500px;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  /* Transition douce pour l'effet de survol */
}

.card-cours:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  /* Effet de survol */
}

.image-cours{
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");





.container-cours {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 40px 0;
}

.container-cours .card-cours {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 30px;
  transition: 0.5s;
}

.container-cours .card-cours:nth-child(1) .box-cours .content-cours a {
  background: #2196f3;
}
.container-cours .card-cours:nth-child(2) .box-cours .content-cours a {
  background: #2196f3;
}
.container-cours .card-cours:nth-child(3) .box-cours .content-cours a {
  background: #2196f3;
}

.container-cours .card-cours:nth-child(4) .box-cours .content-cours a {
  background: #e91e63;
}

.container-cours .card-cours:nth-child(5) .box-cours .content-cours a {
  background: #e91e63;
}

.container-cours .card-cours:nth-child(6) .box-cours .content-cours a {
  background: #23c186;
}

.container-cours .card-cours:nth-child(7) .box-cours .content-cours a {
  background: #c97800;
}

.container-cours .card-cours:nth-child(8) .box-cours .content-cours a {
  background: #309abb;
}

.container-cours .card-cours:nth-child(9) .box-cours .content-cours a {
  background: #a538ee;
}

.container-cours .card-cours .box-cours {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.card-cours .box-cours:hover {
  transform: translateY(-50px);
}

 .card-cours .box-cours:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

.container-cours .card-cours .box-cours .content-cours {
  padding: 20px;
  text-align: center;
}

.container-cours .card-cours .box-cours .content-cours h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

 .container-cours .card-cours .box-cours .content-cours h3 {
  font-size: 1.8rem;
  color: #000;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
  background-color: #CCC;
  border-radius: 15px;
}

.container-cours .card-cours .box-cours .content-cours p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.container-cours .card-cours .box-cours .content-cours a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.container-cours .card-cours .box-cours .content-cours a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}


/* Card Cours */
article {
  --img-scale: 1.001;
  --title-color: black;
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #ffeeb7;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

article a::after {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
}

/* basic article elements styling */
article h2 {
  margin: 0 0 18px 0;
  font-size: 1.9rem;
  letter-spacing: 0.06em;
  color: var(--title-color);
  transition: color 0.3s ease-out;
}

figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

article img {
  max-width: 100%;
  transform-origin: center;
  transform: scale(var(--img-scale));
  transition: transform 0.4s ease-in-out;
}

.article-body {
  padding: 24px;
}

article a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #28666e;
}

article a:focus {
  outline: 1px dotted #28666e;
}

article a .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: translateX(var(--link-icon-translate));
  opacity: var(--link-icon-opacity);
  transition: all 0.3s;
}

/* using the has() relational pseudo selector to update our custom properties */
article:has(:hover, :focus) {
  --img-scale: 1.1;
  --title-color: #28666e;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}




.articles {
  display: grid;
  max-width: 1200px;
  margin-inline: auto;
  padding-inline: 24px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
}

@media screen and (max-width: 960px) {
  article {
    container: card/inline-size;
  }

  .article-body p {
    display: block;
  }
}

@container card (min-width: 380px) {
  .article-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 16px;
  }

  .article-body {
    padding-left: 0;
  }

  figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  figure img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}