/* HeaderSlider.css */

@keyframes zoomInOut {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.header {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  animation: zoomInOut 20s infinite;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.header-image.active {
  opacity: 1;
}

.text-overlay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

.header-text-line {
  font-size: 2rem; /* Taille par défaut pour les petits écrans */
  line-height: 2;
  font-weight: bold;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}

@media (min-width: 640px) { /* Correspond à sm dans Tailwind */
  .header-text-line {
    font-size: 4rem;
  }
}

@media (min-width: 768px) { /* Correspond à md dans Tailwind */
  .header-text-line {
    font-size: 4rem;
  }
}

@media (min-width: 1024px) { /* Correspond à lg dans Tailwind */
  .header-text-line {
    font-size: 4rem;
  }
}

@media (min-width: 1280px) { /* Correspond à xl dans Tailwind */
  .header-text-line {
    font-size: 4rem;
  }
}

@media (min-width: 1536px) { /* Correspond à 2xl dans Tailwind */
  .header-text-line {
    font-size: 5rem;
  }
}
