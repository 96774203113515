.slick-prev:before, .slick-next:before {
  color: black; /* Changer la couleur des flèches si nécessaire */
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slide img {
  display: inline-block;
}
