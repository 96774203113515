.slick-prev:before,
.slick-next:before {
  color: black; /* Changez cette couleur selon votre thème */
}

.slick-dots li button:before {
  color: black; /* Changez cette couleur selon votre thème */
}

.slick-dots li.slick-active button:before {
  color: black; /* Changez cette couleur selon votre thème */
}

.bg-slate-500 {
  background-color: #6b7280; /* Exemple de couleur pour le thème sombre */
}

.bg-white {
  background-color: #ffffff; /* Exemple de couleur pour le thème clair */
}
